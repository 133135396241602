import { Injectable } from '@angular/core';
import { Router, CanActivate, UrlTree } from '@angular/router';
import { AuthService } from './auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { UtilityService } from '../utility.service';
@Injectable()
export class AuthGuardService implements CanActivate{

  constructor(public _auth: AuthService, public _router: Router, public _jwtHelper: JwtHelperService,private _utility:UtilityService) { }
  canActivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (this.tokenControl) {
      return true;
    }
    this._router.navigate(['auth/login']);
    return false
  }
  get tokenControl(){
    let at=window.location.href.split('?token=')[1]?.split('&companyId=')[0];
    if(at){
      let cid=window.location.href.split('?token=')[1].split('&companyId=')[1];
      localStorage.setItem('at',at);
      localStorage.setItem('cid',cid);
      this._utility.companyId=cid;
      return !this._jwtHelper.isTokenExpired(at)
    }else{
      let accessToken=localStorage.getItem('at') ?? null;
      if(accessToken!=null && accessToken!='null'){
        return !this._jwtHelper.isTokenExpired(accessToken)
      }
    } 
    return false;
  }
}


