import {NgModule} from '@angular/core';
import {APOLLO_NAMED_OPTIONS, NamedOptions} from 'apollo-angular';
import {InMemoryCache} from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';
import { environment } from 'src/environments/environment';

const flp_uri = environment.graphqlUrl;


export function createApollo(httpLink: HttpLink): NamedOptions {
  return {
    flp:{
    link: httpLink.create({uri:flp_uri}),
    cache: new InMemoryCache({
      addTypename: false
    })
  }
};
}
@NgModule({
  providers: [
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}