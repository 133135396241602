import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from 'src/app/services/auth/auth.service';
import { BaseService } from 'src/app/services/base/base.service';
import { UtilityService } from 'src/app/services/utility.service';
@Component({
  selector: 'app-apps',
  templateUrl: './apps.component.html',
  styleUrls: ['./apps.component.scss']
})
export class AppsComponent implements OnInit, OnDestroy {

  tabs = [
    { "id": 1, "icon": "dashboard", "name": "dashboard", "route": "dashboard" },
    { "id": 2, "icon": "groups", "name": "drivers", "route": "drivers" },
    { "id": 3, "icon": "local_shipping", "name": "vehicles", "route": "vehicles" },
    { "id": 4, "icon": "home_repair_service", "name": "driver-vehicle", "route": "driver-vehicle" },
    { "id": 5, "icon": "report", "name": "reports", "route": "reports" },
    { "id": 6, "icon": "local_gas_station", "name": "fuel", "route": "fuel" },
    { "id": 7, "icon": "settings", "name": "settings", "route": "management" },
    { "id": 8, "icon": "pin_drop", "name": "vehicle-tracker", "route": "vehicle-tracker" },
    // {"id":6,"icon":"help","name":"help","route":"help"}
  ]
  mobileQuery: MediaQueryList;

  @ViewChild('snav') snav: MatSidenav;

  private _mobileQueryListener: () => void;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private _router: Router,
    public jwtHelper: JwtHelperService,
    private _snackBar: MatSnackBar,
    private _auth: AuthService,
    private _utility: UtilityService,
    private _base: BaseService
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  ngOnInit() {
    setTimeout(() => {
      this.snav.open();
    }, 500);
    this.getUserInfo()
  }

  settings() {
    // this._bottomSheet.open(BottomSheetComponent);
  }

  logout() {
    localStorage.clear();
    this._utility.user.next(null);
    this._router.navigate(['auth/login']);
    
  }

  selectionChange(snav: any) {
    if (this.mobileQuery.matches) {
      snav.close();
    }
  }

  getUserInfo() {
    if (localStorage.getItem('at')) {
       this._utility.companyId=parseInt(localStorage.getItem("cid"));
       this._utility.language("de-DE");
      this._auth.getUserInfo().then((_) => {
        if (_.result) {
          this._utility.user.next(_.data);
          this.getTypes();
        }
      })
      this._utility.tokenInfo = this._auth.tokenInfo();
      this._auth.getApplicationRoles().then((_) => {
        if (_.result) {
          this._utility.applicationRoles = _.data
        }
      })
     
      if (this.jwtHelper.isTokenExpired(localStorage.getItem('at')!)) {
        this._snackBar.open('Uygulamaya tekrar giriş yapmalısınız!', '', { panelClass: ['mat-toolbar', 'snack-warn'], duration: 3000, horizontalPosition: 'right', verticalPosition: 'bottom' });
        this._router.navigate(['auth/login']);
      }
    } else {
      this._snackBar.open('Uygulamaya giriş yapmalısınız!', '', { panelClass: ['mat-toolbar', 'snack-warn'], duration: 3000, horizontalPosition: 'right', verticalPosition: 'bottom' });
      this._router.navigate(['auth/login']);
    }
  }

  getTypes() {
    this._base.getCurrency(0, 100).then((_) => {
      if (_.data) {
        this._utility.currencies = _.data
      }
    })
    this._base.getQuantityTypes(0, 100).then((_) => {
      if (_.data) {
        this._utility.quantityTypes = _.data
      }
    })
    this._base.getSubcontractors(0, 500,this._utility.companyId).then((_) => {
      if (_.data) {
        this._utility.subcontractorList = _.data
      }
    })
    this._base.getVehicleBrand(this._utility.companyId,0, 500).then((_) => {
      if (_.data) {
        this._utility.brands = _.data
      }
    })
  }
}
