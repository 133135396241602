import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { Router } from '@angular/router';
import { MatSnackBar } from "@angular/material/snack-bar";
@Injectable({
  providedIn: "root"
})
export class ApiService {
  accessToken:string | undefined | null;
  constructor(
    private http: HttpClient,
    private _router:Router,
    private _snackBar: MatSnackBar
  ) { }

  getOptions(): any {
    var httpHeaders: any;
    if (this.tokenControl) {
      httpHeaders = new HttpHeaders()
      .set(
        "Authorization",
        "Bearer " + this.accessToken
      )
    }
    let options = {
      headers: httpHeaders
    };
    return options;
  }

  async get(url: any): Promise<any> {
    if (this.tokenControl) {
      var opt = this.getOptions();
      return this.http
        .get<any>(url,opt)
        .toPromise()
        .then(result => {
          return result;
        })
        .catch(error => {
          console.log(error)
          this.errorAuth(error)
          return throwError(error);
        });
    }
  }

  async getWithOptions(url: any, params: HttpParams): Promise<any> {
      var httpHeaders: any;
      if (this.tokenControl) {
        httpHeaders = new HttpHeaders().set(
          "Authorization",
          "Bearer " + this.accessToken
        );
      let params1 = new HttpParams();
      params1 = params;
      var options1: {
        headers?: HttpHeaders;
        observe?: "body";
        params?: HttpParams;
        reportProgress?: boolean;
        withCredentials?: boolean;
      } = {
        headers: httpHeaders,
        params: params1
      };

      return this.http
        .get<any>(url, options1)
        .toPromise()
        .then(res => {
          return res;
        })
        .catch(error => {
          this.errorAuth(error)
          return throwError(error);
        });
    }
  }

  async post(url: any, data: any): Promise<any> {
    if (this.tokenControl) {
      var opt = this.getOptions();
      return this.http
        .post<any>(url, data, opt)
        .toPromise()
        .then(res => {        
          return res;
        })
        .catch(error => {
          this.errorAuth(error)
          return throwError(error);
        });
    }
  }

  async put(url: any, data: any): Promise<any> {
    if (this.tokenControl) {
      var opt = this.getOptions();
      return this.http
        .put<any>(url, data, opt)
        .toPromise()
        .then(res => {        
          return res;
        })
        .catch(error => {
          this.errorAuth(error)
          return throwError(error);
        });
    }
  }

  async delete(url: any): Promise<any> {
    if (this.tokenControl) {
      var opt = this.getOptions();
      return this.http
        .delete<any>(url,opt)
        .toPromise()
        .then(result => {
          return result;
        })
        .catch(error => {
          console.log(error)
          this.errorAuth(error)
          return throwError(error);
        });
    } 
  }

  getMethod(url: any, params: HttpParams):Observable<any>{
    var httpHeaders: any;
    if (this.tokenControl) {
      httpHeaders = new HttpHeaders().set(
        "Authorization",
        "Bearer " + this.accessToken
      );
    }
    let params1 = new HttpParams();
    params1 = params;
    var options1: {
      headers?: HttpHeaders;
      observe?: "body";
      params?: HttpParams;
      reportProgress?: boolean;
      withCredentials?: boolean;
    } = {
      headers: httpHeaders,
      params: params1
    };
    return this.http.get<any>(url, options1);
  }

  async deleteWithOptions(url: any, params: HttpParams): Promise<any> {

    var httpHeaders: any;
    if (this.tokenControl) {
      httpHeaders = new HttpHeaders().set(
        "Authorization",
        "Bearer " + this.accessToken
      );
      let params1 = new HttpParams();
      params1 = params;
      var options1: {
        headers?: HttpHeaders;
        observe?: "body";
        params?: HttpParams;
        reportProgress?: boolean;
        withCredentials?: boolean;
      } = {
        headers: httpHeaders,
        params: params1
      };

      return this.http
        .delete<any>(url, options1)
        .toPromise()
        .then(res => {
          return res;
        })
        .catch(error => {
          this.errorAuth(error)
          return throwError(error);
        });
    }
  }

  errorAuth(e:HttpErrorResponse){
    this._snackBar.open(e.message, '', { panelClass: ['mat-toolbar','mat-warn'], duration: 3000, horizontalPosition: 'center', verticalPosition: 'bottom' });
    if(e.status==401){
      
    }
    if(e.status==0){
     // this._router.navigate(['auth/login']);
    }
  }

  get tokenControl() {
      this.accessToken=localStorage.getItem('at');
      if(this.accessToken){
        return true
      }
      this._router.navigate(['auth/login']);
      return false;
  }
  
}

