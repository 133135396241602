import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  apiUrl: String = environment.gatewayApiUrl;

  constructor(
    private _api: ApiService,
    private _http: HttpClient

  ) { }

  async getCustomFields(page: number, limit: number): Promise<any> {
    const params = new HttpParams()
      .set("page", page.toString())
      .set("limit", limit.toString())
    const result = await this._api.getWithOptions(this.apiUrl + "/base-service/getcustomfields", params);
    return result;
  }
  async getCurrency(page: number, limit: number): Promise<any> {
    const params = new HttpParams()
      .set("page", page.toString())
      .set("limit", limit.toString())
    const result = await this._api.getWithOptions(this.apiUrl + "/base-service/getcurrency", params);
    return result;
  }
  async addCurrency(model: any): Promise<any> {
    const result = await this._api.post(this.apiUrl + "/base-service/addcurrency", model);
    return result;
  }
  async updateCurrency(model: any): Promise<any> {
    const result = await this._api.post(this.apiUrl + "/base-service/updatecurrency", model);
    return result;
  }
  async deleteCurrency(id: any): Promise<any> {
    let params = new HttpParams()
      .set("id", id)
    const result = await this._api.deleteWithOptions(this.apiUrl + "/base-service/deletecurrency", params);
    return result;
  }
  async getQuantityTypes(page: number, limit: number): Promise<any> {
    const params = new HttpParams()
      .set("page", page.toString())
      .set("limit", limit.toString())
    const result = await this._api.getWithOptions(this.apiUrl + "/base-service/getquantitytypes", params);
    return result;
  }

  async getSubcontractors(page:number,limit:number,companyId:number){
    const result = await this._api.get(this.apiUrl + "/subcontractor-service/get/"+companyId+"/"+page.toString()+"/"+limit.toString());
    return result;
  }

  async addQuantityType(model: any): Promise<any> {
    const result = await this._api.post(this.apiUrl + "/base-service/addquantitytype", model);
    return result;
  }
  async updateQuantityType(model: any): Promise<any> {
    const result = await this._api.post(this.apiUrl + "/base-service/updatequantitytype", model);
    return result;
  }
  async deleteQuantityType(id: any): Promise<any> {
    let params = new HttpParams()
      .set("id", id)
    const result = await this._api.deleteWithOptions(this.apiUrl + "/base-service/deletequantitytype", params);
    return result;
  }
  async getTags(page: number, limit: number): Promise<any> {
    const params = new HttpParams()
      .set("page", page.toString())
      .set("limit", limit.toString())
    const result = await this._api.getWithOptions(this.apiUrl + "/base-service/gettags", params);
    return result;
  }
  getTagSuggestionNames(path: string): Observable<any> {
    const token = localStorage.getItem('at');
    var httpHeaders: any;
    if (token != null) {
      httpHeaders = new HttpHeaders().set(
        "Authorization",
        "Bearer " + token
      );
    }
    let options = {
      headers: httpHeaders
    };
    return this._http.get<any>(this.apiUrl + "/base-service/gettagsuggestionnames/" + path, options)
  }
  async AddTag(model: any): Promise<any> {
    const result = await this._api.post(this.apiUrl + "/base-service/addtag", model);
    return result;
  }
  async UpdateTag(model: any): Promise<any> {
    const result = await this._api.post(this.apiUrl + "/base-service/updatetag", model);
    return result;
  }
  async DeleteTag(id: any): Promise<any> {
    let params = new HttpParams()
      .set("id", id)
    const result = await this._api.deleteWithOptions(this.apiUrl + "/base-service/deletetag", params);
    return result;
  }

  async getVehicleBrand(companyId:any,page: number, limit: number): Promise<any> {
    const result = await this._api.get(this.apiUrl + "/vehiclebrand-service/get/"+companyId+'/'+page+'/'+limit);
    return result;
  }

  async getVehicleModel(companyId:any,brandId:number,page: number, limit: number): Promise<any> {
    const result = await this._api.get(this.apiUrl + "/vehiclemodel-service/get/"+companyId+'/'+brandId+'/'+page+'/'+limit);
    return result;
  }

}
