import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { JwtHelperService } from "@auth0/angular-jwt";
import { map, catchError, finalize } from 'rxjs/operators';
import { ApiService } from '../api.service';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: HttpClient, public jwtHelper: JwtHelperService, private _api: ApiService) { }

  login(data: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post<any>(environment.gatewayApiUrl + '/auth-service/token', data, { headers }).pipe(
      map((_) => {
        if (_.httpStatusCode == 200) {
          localStorage.setItem('at', _?.data.access_token)
          return true;
        }
        return false;
      }),
      catchError(error => {
        return throwError(error);

      }),
      finalize(() => {
      })
    );
  }

  tokenInfo() {
    let decoded = this.jwtHelper.decodeToken(localStorage.getItem('at')!);
    return decoded
  }

  async getUserInfo(): Promise<any> {
    let decoded = this.jwtHelper.decodeToken(localStorage.getItem('at')!);
    return await this._api.get(environment.gatewayApiUrl + "/auth-service/user/" + decoded.given_name);
  }

  async getUserList(page: number, limit: number,companyId:any,searchText:string): Promise<any> {
    let params = new HttpParams()
      .set("companyId", companyId.toString())
      .set("page", page.toString())
      .set("limit", limit.toString())
      .set('searchText',searchText??"");
    const result = await this._api.getWithOptions(environment.gatewayApiUrl + "/auth-service/getusers", params);
    return result;
  }

  async getUsersWithRole(page: number, limit: number,role:string,searchText:any): Promise<any> {
    let params = new HttpParams()
      .set("page", page.toString())
      .set("limit", limit.toString())
      .set('searchText',searchText??"");
    const result = await this._api.getWithOptions(environment.gatewayApiUrl + "/auth-service/getuserswithrole/"+role, params);
    return result;
  }

  async getApplicationRoles(): Promise<any> {
    const result = await this._api.get(environment.gatewayApiUrl + "/auth-service/roles");
    return result;
  }

  async changePassword(pwd: any): Promise<any> {
    let decoded = this.jwtHelper.decodeToken(localStorage.getItem('at')!);
    const model = {
      username: decoded.given_name,
      currentPassword: pwd.currentPassword,
      newPassword: pwd.newPassword
    }
    const result = await this._api.post(environment.gatewayApiUrl + "/auth-service/changepassword", model);
    return result;
  }

  async forgotPassword(model: any) {
    const result = await this._api.post(environment.gatewayApiUrl + "/auth-service/forgotpassword", model);
    return result;
  }

  async resetPassword(model: any) {
    const result = await this._api.post(environment.gatewayApiUrl + "/auth-service/resetpassword", model);
    return result;
  }

  async addApplicationUserWithRole(user: any): Promise<any> {
    const result = await this._api.post(environment.gatewayApiUrl + "/auth-service/adduserwithrole", user);
    return result;
  }

  async deleteApplicationUser(username: any): Promise<any> {
    let params = new HttpParams()
      .set("username", username)
    const result = await this._api.deleteWithOptions(environment.gatewayApiUrl+ "/auth-service/deleteuser", params);
    return result;
    // const result = await this._api.delete(environment.authApiUrl + "/DeleteApplicationUser/" + username);
    // return result;
  }

  async updateApplicationUserWithRole(user: any): Promise<any> {
    const model = {
      "firstName": user.name,
      "lastName": user.surname,
      "status": user.status,
      "role": user.role,
      "password": user.password,
      "userName": user.username,
      "email": user.email,
      "phoneNumber": user.phoneNumber,
      "authorizedFolders": user.authorizedFolders
    }
    const result = await this._api.post(environment.gatewayApiUrl + "/auth-service/updateuserwithrole", model);
    return result;
  }

  async regionForUser(body:any): Promise<any> {
    const result = await this._api.post(`${environment.gatewayApiUrl}/user-service/regionforuser`,body);
    return result;
  }

  async getRegionForUser(username:String): Promise<any> {
    const result = await this._api.get(`${environment.gatewayApiUrl}/user-service/regionforuser/${username}`);
    return result;
  }
}
