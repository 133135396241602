import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { format, formatDistanceToNow, formatDuration, formatISO, intervalToDuration } from 'date-fns';
import { tr } from 'date-fns/locale';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { HttpClient } from '@angular/common/http';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  apiUrl: String = environment.gatewayApiUrl;

  companyId:any;

  currentItem: any;

  user=new Subject();

  userInfo:any;

  tokenInfo: any;

  currencies: any;

  quantityTypes: any;

  applicationRoles: any;

  subcontractorList:any;

  brands:any;

  models:any;

  private _loading: boolean = false;
  loadingStatus: any = new Subject();

  changedFolderList: any;

  regionList=[];

  translateData: any = {};

  constructor(private _snackBar: MatSnackBar, private _http: HttpClient, ) { }

  dateFormat(date: string, version: number) {
    switch (version) {
      case 1: // Örnek çıktı : yaklaşık 4 saat önce
        var cd = date.split('T')[0];
        let time = date.split('T')[1].split('Z')[0];
        var result = formatDistanceToNow(new Date(cd + ' ' + time), { includeSeconds: true, addSuffix: true, locale: tr });
        return result;
      case 2: // Örnek çıktı : 2020/10/15 09:00
        var transformData = format(new Date(date), 'yyyy-MM-dd HH:mm');
        return transformData;
      case 3: // Örnek çıktı : 2020/10/15 09
        var transformData = format(new Date(date), 'yyyy-MM-dd HH');
        return transformData;
      case 4:// Örnek çıktı : 2020/10/15
        var transformData = format(new Date(date), 'yyyy-MM-dd');
        return transformData;
      case 5:// Örnek çıktı : 2019-09-18T19:00:52Z
        const iso = formatISO(new Date(date));
        return iso;
      case 6:// Örnek çıktı : 1 gün 5 saat 9 dakika önce
        var transformData = format(new Date(date), 'yyyy-MM-dd HH:mm:ss');
        var lastUpdate = intervalToDuration({ start: new Date(transformData), end: new Date() });
        var result = formatDuration(lastUpdate, { locale: tr });
        return result;
      case 7:// Örnek Çıktı : 2020/10/15 09:00:00
        var transformData = format(new Date(date), 'yyyy-MM-dd HH:mm:ss');
        return transformData;
      case 8:// Örnek Çıktı : 2020/10/15 09:00:00 AM
        var transformData = format(new Date(date), 'yyyy-MM-dd HH:mm:ss a');
        return transformData;
      case 9:
        var transformData = format(new Date(date), 'HH:mm');
        return transformData;
      default:
        return null;
    }
  }

  

  // Depth-first search
  dfs(obj: any, child: any, property: any, target: any) {
    if (obj[property] === target) {
      return obj
    }
    if (obj[child]) {
      for (let item of obj[child]) {
        let check: any = this.dfs(item, child, property, target)
        if (check) {
          return check
        }
      }
    }
    return null
  }

  toCamel(o: any) {
    let ths = this;
    var newO: any, origKey: any, newKey: any, value: any
    if (o instanceof Array) {
      return o.map(function (value) {
        if (typeof value === "object") {
          value = ths.toCamel(value)
        }
        return value
      })
    } else {
      newO = {}
      for (origKey in o) {
        if (o.hasOwnProperty(origKey)) {
          newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString()
          value = o[origKey]
          if (value instanceof Array || (value !== null && value.constructor === Object)) {
            value = ths.toCamel(value)
          }
          newO[newKey] = value
        }
      }
    }
    return newO
  }

  groupBy(objectArray: any, property: any) {
    return objectArray.reduce(function (acc: any, obj: any) {
      var key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  }

  snackbar(message: string, code: boolean) {
    this._snackBar.open(message, '', { panelClass: ['mat-toolbar', code ? 'mat-primary' : 'mat-warn'], duration: 3000, horizontalPosition: 'right', verticalPosition: 'bottom' });
    this.loading = false;
  }

  generatePdf(content: any) {
    let docDefinition: any = {
      content: content
    };
    pdfMake.createPdf(docDefinition).open();
  }

  get loading(): boolean {
    return this._loading;
  }

  set loading(value) {
    this._loading = value;
    this.loadingStatus.next(value);
  }

  startLoading() {
    this.loading = true;
  }

  stopLoading() {
    this.loading = false;
  }

  dataURLtoFile(dataurl: any, filename: any) {

    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  saveAs(document: any, name: string) {
    FileSaver.saveAs(document, name);
  }

  language(lang: string): Promise<{}> {
    return new Promise<any>((resolve, reject) => {
      const langPath = `assets/i18n/i18n.json`;   
      this._http.get<any>(langPath).subscribe(
        (translation:any) => {
          Object.keys(translation).forEach((key) => {
            translation[key] = translation[key][lang];
          });
          this.translateData = Object.assign({}, translation || {});
          resolve(this.translateData);
        },
        error => {
          this.translateData = {};
          resolve(this.translateData);
        }
      );
    });
  }

  transform(key: any): any {
    return this.translateData[key];
  }

  getBinaryFromFile(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.addEventListener("load", () => resolve(reader.result));
        reader.addEventListener("error", err => reject(err));

        reader.readAsBinaryString(file);
    });
  }
  
  exportExcel(data:any,name:string){
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data)
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, name);
    XLSX.writeFile(wb, name+'.xlsx');  
  }

}
